@import "https://fonts.googleapis.com/css2?family=Architects+Daughter&family=Gloria+Hallelujah&display=swap";
@import "https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap";
.gloria-hallelujah-regular {
  font-family: Gloria Hallelujah, cursive;
  font-style: normal;
  font-weight: 400;
}

body, h1, h2, h3, h4, h5, h6 {
  font-family: Lato, sans-serif;
}

body, html {
  color: #777;
  text-align: justify;
  height: 100%;
  line-height: 1.8;
}

.my-beach-blue {
  color: #fff;
  background-color: #6bc2dd;
}

.my-beach-blue-light {
  color: #fff;
  background-color: #55c4d7;
}

.my-beach-orange {
  color: #fff;
  background-color: #eac47d;
}

.my-beach-cream {
  background-color: #f0df9940;
  background-color: #f0df99 !important;
}

.my-beach-cream-25 {
  background-color: #f0df9940;
  background-color: #f0df9940 !important;
}

.my-border-beach-orange {
  border: 1px solid #eac47d;
}

.my-hover-beach-blue:hover {
  color: #fff !important;
  background-color: #6bc2dd !important;
}

.my-hover-beach-orange:hover {
  color: #fff !important;
  background-color: #eac47d !important;
}

.my-hover-border-beach-orange:hover {
  color: #fff !important;
  border: 1px solid #eac47d !important;
}

.my-hover-bold:hover {
  font-weight: bold;
}

.initial-hidden {
  visibility: hidden;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.loaded-show {
  opacity: 1;
  visibility: visible;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    visibility: hidden;
  }

  to {
    opacity: 1;
    visibility: visible;
  }
}

.fade-in {
  animation: 1s forwards fadeIn;
}

.my-flex-middle {
  flex-direction: column;
  justify-content: center;
  height: 100%;
  display: flex;
}

#my-title {
  font-family: Gloria Hallelujah, Lato, sans-serif;
  font-weight: bold;
}

.bgimg-1, .bgimg-2, .bgimg-3 {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
}

.bgimg-2 {
  background-image: url("beach-2.fc69e830.webp");
  min-height: 400px;
}

.bgimg-3 {
  background-image: url("beach.a92769d1.webp");
  min-height: 400px;
}

.w3-wide {
  letter-spacing: 10px;
}

.w3-hover-opacity {
  cursor: pointer;
}
/*# sourceMappingURL=index.60329971.css.map */
