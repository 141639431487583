.initial-hidden {
  visibility: hidden;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.loaded-show {
  opacity: 1;
  visibility: visible;
}

@keyframes fadeIn {
  from {
      opacity: 0;
      visibility: hidden;
  }
  to {
      opacity: 1;
      visibility: visible;
  }
}

.fade-in {
  animation: fadeIn 1s forwards;
}

.my-flex-middle {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#my-title {
  font-family: "Gloria Hallelujah", "Lato", sans-serif;
  font-weight: bold;
}

/* Create a Parallax Effect */
.bgimg-1,
.bgimg-2,
.bgimg-3 {
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}


/* Second image (Portfolio) */
.bgimg-2 {
  background-image: url("./images/beach-2.webp");
  min-height: 400px;
}

/* Third image (Contact) */
.bgimg-3 {
  background-image: url("./images/beach.webp");
  min-height: 400px;
}

.w3-wide {
  letter-spacing: 10px;
}

.w3-hover-opacity {
  cursor: pointer;
}